import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { PaymentDisclaimer } from 'apps-common/components/PaymentDisclaimer';
import { ProductSelector } from 'apps-common/components/ProductSelector';
import { useGetMembershipOfferings } from 'apps-common/hooks/useGetMembershipOfferings';
import { useGetProducts } from 'apps-common/hooks/useGetProducts';
import { BillingPeriod, RatePlan } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { getRatePlansFromProducts } from 'apps-common/utils/getProduct';
import { logger } from 'apps-common/utils/logger';
import { t } from 'translations';
import { Form, Header, Loader, SubmitButton } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { routes } from '../routes';
import { useStore } from '../store';
import { getSignupBannerText } from '../utils/helpers';

export const ProductPage = () => {
  const [membershipPlans, setMembershipPlans] = useState<RatePlan[] | null>(null);
  const { data: offerings, isFetching } = useGetMembershipOfferings();

  const navigate = useNavigate();
  const userAddressForm = useStore((state) => state.userAddressForm!);
  const setRatePlan = useStore((state) => state.setRatePlan);
  const signupInfo = useStore((state) => state.signupInfo);

  const {
    data: productsData,
    isFetching: isFetchingProducts,
    error: errorOnProducts,
  } = useGetProducts(userAddressForm.shippingAddress.country);
  if (errorOnProducts) {
    throwError('noProductFound', errorOnProducts);
  }

  const { formState, handleSubmit, register } = useForm<{
    product: BillingPeriod;
  }>({
    values: {
      product: BillingPeriod.Years, // always show years as first option
    },
  });

  useEffect(() => {
    if (productsData?.products) {
      const ratePlans = getRatePlansFromProducts(productsData.products);
      setMembershipPlans(ratePlans);
    }
  }, [productsData?.products]);

  const prepaidMonths = offerings?.prepaidMonths ?? 0;
  const isPrepaid = prepaidMonths > 0;
  const TITLE = t('membership_hub_ongoing_plan');
  const SUBTITLE = isPrepaid
    ? t('membership_hub_choose_ongoing_plan_after_prepaid', {
        months: prepaidMonths,
      })
    : t('membership_hub_signup_product_subtitle_normal_flow');
  const BANNER_TEXT = getSignupBannerText(isPrepaid, prepaidMonths);

  const header = (
    <Header
      appType="signup"
      pageType="create"
      title={TITLE}
      subTitle={SUBTITLE}
      bannerText={BANNER_TEXT}
      testId="membership-plan-header"
      steps={{ current: 2, total: 3 }}
      onBackClick={() => navigate(routes.address)}
      ringSerialStatus={signupInfo.ringSerialStatus}
    />
  );

  if (isFetching || isFetchingProducts || !membershipPlans) {
    return (
      <>
        {header}
        <MainContainer>
          <Loader />
        </MainContainer>
      </>
    );
  }

  const onSubmit = handleSubmit((data) => {
    const { product: billingPeriod } = data;
    const ratePlan = membershipPlans.find((p) => p.billingPeriod === billingPeriod)!;

    setRatePlan(ratePlan);
    track({
      event: 'Membership Plan Selected',
      payload: {
        productType: ratePlan.billingPeriod,
      },
    });

    logger.info('Plan selected, continuing to payment method page');
    navigate(routes.paymentMethod);
  });

  return (
    <>
      <Header
        appType="signup"
        pageType="create"
        title={TITLE}
        subTitle={SUBTITLE}
        bannerText={BANNER_TEXT}
        testId="membership-plan-header"
        steps={{ current: 2, total: 3 }}
        onBackClick={() => navigate(routes.address)}
        ringSerialStatus={signupInfo.ringSerialStatus}
      />
      <MainContainer>
        {(!isFetching || !isFetchingProducts) && (
          <Form onSubmit={onSubmit}>
            <ProductSelector ratePlans={membershipPlans} {...register('product', { required: true })} />
            <SubmitButton disabled={!formState.isValid} data-testid="membership-plan-next-button">
              {t('membership_signup_button_next')}
            </SubmitButton>
            <PaymentDisclaimer />
          </Form>
        )}
      </MainContainer>
    </>
  );
};
